import { AuthUserProvider, useAuth } from "../lib/authUserContext";
import type { AppProps } from "next/app";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import IdleScreen from "../components/idleScreen";
import "./styles.css";
import { useRouter } from "next/router";
import Link from "next/link";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <AuthUserProvider>
      {/* @ts-ignore */}
      {Component.noAuth ? (
        <Component {...pageProps} />
      ) : (
        <>
          {/* @ts-ignore */}
          <Auth allowRoles={Component.allowRoles}>
            <Component {...pageProps} />
          </Auth>
        </>
      )}
    </AuthUserProvider>
  );
}

function Auth({
  children,
  allowRoles = [],
}: {
  children: React.ReactNode;
  allowRoles: string[];
}) {
  const { authUser, loading } = useAuth();

  const router = useRouter();
  useEffect(() => {
    if (loading) return; // Do nothing while loading
    if (!authUser?.token) {
      if (router.route === "/") router.push("/landing");
      else
        router.push(`/signup?redirectUrl=${encodeURIComponent(router.asPath)}`); // If not authenticated, force log in
    }
  }, [authUser?.token, loading, router]);

  if (!loading && authUser) {
    const { role, name, email } = authUser;
    if (role === "admin" || allowRoles.includes(role)) return children;
    else
      return (
        <IdleScreen>
          <h1>Access Denied</h1>
          <h3>Hello, {name}</h3>
          <p>
            You are not authorized to view this page.
            <br />
            Please use the invitation link to access the event.
          </p>
          <p>
            <Link href="/signout">
              <a>
                Not <em>{email}</em> ? Click here to sign out.
              </a>
            </Link>
          </p>
        </IdleScreen>
      );
  }

  return (
    <IdleScreen>
      <div style={{ width: "128px" }}>
        <FontAwesomeIcon icon={faSpinner} className="spin" />
      </div>
    </IdleScreen>
  );
}
