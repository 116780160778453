import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { browserLocalPersistence, getAuth, initializeAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASEURL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
};

const initFirebase = () => {
  const apps = getApps();
  if (!apps.length) {
    const app = initializeApp(firebaseConfig);
    initializeAuth(app, {
      persistence: browserLocalPersistence,
      popupRedirectResolver: undefined,
    });
  }
}

initFirebase();

export const db = getFirestore();
export const fb = getDatabase();
export const auth = getAuth();