import { NextOrObserver, User, sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword, UserCredential, updateProfile, IdTokenResult } from 'firebase/auth';
import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router';
import { auth } from './firebase';
// import { adminauth } from './firebase-admin';

export interface AppUser {
  uid: string;
  email: string;
  name?: string;
  photoURL?: string
  role: "admin" | "student" | string;
  token: string;
}
export interface IFirebaseAuthContext {
  authUser?: AppUser;
  loading: boolean;
  signIn?: (email: string, password: string) => Promise<UserCredential>;
  signUp?: (name: string, email: string, password: string, redirectUrl?: string) => Promise<UserCredential>;
  signOut?: () => Promise<void>,
  resetPassword?: (email: string) => Promise<void>
}
const formatAuthUser = (user: User, idTokenResult: IdTokenResult): AppUser => ({
  uid: user.uid,
  email: user.email,
  name: user.displayName,
  photoURL: user.photoURL,
  role: idTokenResult.claims.role as string || "student",
  token: idTokenResult.token
});

export default function useFirebaseAuth(): IFirebaseAuthContext {
  const router = useRouter();
  const [authUser, setAuthUser] = useState<AppUser>(null);
  const [loading, setLoading] = useState(true);

  const authStateChanged: NextOrObserver<User> = useCallback(async (authState) => {
    if (!authState) {
      setAuthUser(null)
      setLoading(false)
      return;
    }
    if (authUser?.token) return
    console.log("AuthState Changed Reset: ", authState?.displayName);
    setLoading(true)
    const idTokenResult = await authState.getIdTokenResult();
    const formattedUser = formatAuthUser(authState, idTokenResult);
    setAuthUser(formattedUser);
    setLoading(false);
  }, [authUser?.token]);
  const signIn = (email: string, password: string) => signInWithEmailAndPassword(auth, email, password)
  const signUp = async (displayName: string, email: string, password: string, redirectUrl: string) => {
    setLoading(true)
    const userCred = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(userCred.user, { displayName });
    alert("User Created. Please signin to continue");
    await auth.signOut();
    setAuthUser(null);
    setLoading(false);
    router.push(`/signin?redirectUrl=${redirectUrl}`)
    return userCred;
  }

  const resetPassword = (email) => sendPasswordResetEmail(auth, email)

  const signOut = async () => {
    await auth.signOut();
    setAuthUser(null);
    setLoading(false);
    router.push("/landing")
  }
  // listen for Firebase state change
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authStateChanged, console.error, console.log);
    return () => unsubscribe();
  }, [authStateChanged]);

  return {
    authUser,
    loading,
    signIn,
    signUp,
    signOut,
    resetPassword
  };
}