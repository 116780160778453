import { createContext, useContext, Context } from 'react'
import useFirebaseAuth, { IFirebaseAuthContext } from './useFirebaseAuth';

const AuthUserContext = createContext<IFirebaseAuthContext>({
  authUser: null,
  loading: true
});

export function AuthUserProvider({ children }) {
  const auth = useFirebaseAuth();
  return <AuthUserContext.Provider value={auth}> {children} </AuthUserContext.Provider>;
}
// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(AuthUserContext);